export const Locale = {
  jaJP: 'jaJP',
} as const;

export type Locale = (typeof Locale)[keyof typeof Locale];

export type ErrorMessages = {
  network: (args: { reason: string }) => string;
  authorization: () => string;
};

export type ValidationMessages = {
  username: (args: { min: number; max: number }) => string;
  password: (args: { min: number; max: number }) => string;
  jsonSchema: (args: { kinds: string }) => string;
  hankaku: () => string;
};

export type FetchMsg = {
  // フェッチ関連のメッセージ
  failToGet: (args: { kinds: string | undefined }) => string;
};

export type OperationMsg = {
  // 操作関連のメッセージ
  fail: (args: { kinds: string }) => string;
  save: (args: { kinds: string }) => string;
  failToSave: (args: { kinds: string }) => string;
  add: (args: { kinds: string }) => string;
  failToAdd: (args: { kinds: string }) => string;
  delete: (args: { kinds: string }) => string;
  failToDelete: (args: { kinds: string }) => string;
  update: (args: { kinds: string }) => string;
  failToUpdate: (args: { kinds: string }) => string;
  create: (args: { kinds: string }) => string;
  failToCreate: (args: { kinds: string }) => string;
  upload: (args: { kinds: string }) => string;
  failToUpload: (args: { kinds: string }) => string;
  register: (args: { kinds: string }) => string;
  failToRegister: (args: { kinds: string }) => string;
  alreadyRegistered: (args: { kinds: string }) => string;
  alreadyDeleted: (args: { kinds: string }) => string;
  alreadyProcessing: (args: { kinds: string }) => string;
  failToParse: (args: { kinds: string }) => string;
  failToSetting: (args: { kinds: string }) => string;
  downloaded: (args: { kinds: string }) => string;
  failToDownload: (args: { kinds: string }) => string;
  select: (args: { kinds: string }) => string;
  failToSelect: (args: { kinds: string }) => string;
  login: (args: { kinds: string }) => string;
  failToLogin: (args: { kinds: string }) => string;
  failToVerify: (args: { kinds: string }) => string;
};

export type unicodeCategories = {
  error: ErrorMessages;
  validation: ValidationMessages;
  fetch: FetchMsg;
  operation: OperationMsg;
  // ...他
};

export type unicodeFactory = {
  [locale in Locale]: unicodeCategories;
};
