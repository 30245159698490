/**
 * @fileOverview
 * @name FirestoreService.ts<service>
 * @author Taketoshi Aono
 * @license
 */

import { Action } from 'redux';
import { State } from '@c/state';
import { FirestoreConnectable } from '@c/firebase/FirestoreConnector';
import { v4 } from 'uuid';
import { staticConfig } from '@c/config';
import { createRetryHandler } from '@s/io/createRetryHandler';
import { ChatAssignType, ChatEntity, FirestoreChatEntity } from '../entities/Chat';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import { MessageFormat } from '@s/domain/entity/MessageFormat';
import { LruCache } from '@c/util/LruCache';
import { CustomerEntity, RemoteCustomerEntity } from '../entities/Customer';
import { VoiceCustomer } from '@c/domain/entities/voice/VoiceCustomer';
import { Conversation, formatVoiceEntity } from '@c/domain/entities/voice/Conversation';
import { ConversationEvent } from '@c/domain/entities/voice/ConversationEvent';
import { FirebaseError, isFirebaseError } from '@c/io/firebaseErrorHandler';
import { ThunkDeps } from '@c/ThunkDeps';
import { checkLoginState } from '@c/modules/auth/usecase';
import { AsyncActionContext } from '@s/reactHooks';
import {
  CollectionReference,
  DocumentChange,
  DocumentData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';

type C = AsyncActionContext<ThunkDeps, State>;

export interface FirestoreDomainService {
  releaseAllConnection(): Promise<void>;
  measureConnectivity(a: {
    tenantId: string;
    operatorId: string;
    onDisconnect(): void;
    onConnect(): void;
    onReauth(): Promise<void>;
    onPingError(e: any): void;
  }): void;
  getArchivedConversationList(a: {
    projectId: string | string[];
    tenantId: string;
    archivedDateRange: { from: Date; to: Date };
  }): Promise<ChatEntity[]>;
  getArchivedConversations(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): Promise<ChatEntity[]>;
  getCustomer(a: {
    onUpdate(customer: CustomerEntity): void;
    tenantId: string;
    projectId: string;
    customerId: string;
    onError(error: any): void;
    onReauth(): Promise<boolean>;
  }): Promise<CustomerEntity>;
  getArchivedMessages(a: {
    customerId: string;
    tenantId: string;
    projectId: string;
    start: number;
    end: number;
  }): Promise<DisplayableMessageFormat[]>;
  handleSubscribeConversationError(
    shouldRetry: boolean,
    error: FirebaseError,
    { dispatch, context, state, getState }: C,
    onRefreshTokenSuccess: () => void,
    onError: () => void
  ): Promise<void>;
  storeIncidentState(a: { state: State; log: Action[]; operatorId: string }): Promise<string>;
  subscribeConversationViews(a: {
    tenantId: string;
    operatorId: string;
    filterType: ChatAssignType;
    projectId: string;
    subscriptions: Map<ChatAssignType, () => void>;
    onBatchAdd(entity: ChatEntity[]): void;
    onAdd(entity: ChatEntity): void;
    onUpdate(oldIndex: number, newIndex: number, entity: ChatEntity): void;
    onDelete(entity: ChatEntity): void;
    onError(error: any): void;
    onReauth(): Promise<boolean>;
  }): Promise<void>;
  subscribeChatMessages(a: {
    customerId: string;
    tenantId: string;
    projectId: string;
    start: number;
    isChatStarted: boolean;
    onDisconnect(): void;
    onBatchAdd(a: { messages: DisplayableMessageFormat[]; isReconnected: boolean }): void;
    onAdd(entity: DisplayableMessageFormat[], isChatWithOperator: boolean): void;
    onRemove(entity: DisplayableMessageFormat[]): void;
    onTypingUpdate(a: { isTyping: boolean; text: string }): void;
    onUpdateEnableCustomerImageUploadState(a: { enable: boolean }): void;
    onError(error: any): void;
    onReauth(): Promise<boolean>;
  }): Promise<void>;
  unsubscribeAllChatMessages(a: { tenantId: string; projectId: string }): void;
  unsubscribeChatMessages(a: { customerId: string; projectId: string }): void;
  updateCacheRecency(a: { customerId: string; projectId: string }): void;
  updateTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
    isTyping: boolean;
  }): Promise<void>;
  subscribeTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
    onUpdate(a: { text: string; isTyping: boolean }): void;
    onError(error: any): void;
    onReauth(): Promise<boolean>;
  }): Promise<void>;
  subscribeVoiceConversation(a: {
    tenantId: string;
    projectId: string;
    onAdd(conversation: Conversation): void;
    onUpdate(conversation: Conversation): void;
    onDelete(conversation: Conversation): void;
    onError(error: any): void;
  }): Promise<void>;
  subscribeVoiceConversationEvent(a: {
    tenantId: string;
    voiceCustomerId: string;
    projectId: string;
    conversationId: string;
    onBatchAdd(conversationEvents: ConversationEvent[]): void;
    onAdd(conversationEvent: ConversationEvent): void;
    onUpdate(conversationEvent: ConversationEvent): void;
    onDelete(conversationEvent: ConversationEvent): void;
    onError(error: any): void;
  }): Promise<void>;
}

class FirestoreConnectionCacheController {
  private readonly connectionLruCache: LruCache<{
    disposeConversationConnection(): void;
    disposeCustomerConnection?(): void;
    disposeTypingConnection?(): void;
  }>;

  private unsubscribeConnectivityCheckConnection?: () => Promise<void>;

  public constructor(maxChatMessageConnection: number) {
    this.connectionLruCache = new LruCache(maxChatMessageConnection);
    this.connectionLruCache.onDelete(
      (
        key,
        { disposeTypingConnection, disposeConversationConnection, disposeCustomerConnection }
      ) => {
        disposeConversationConnection();
        disposeTypingConnection && disposeTypingConnection();
        disposeCustomerConnection && disposeCustomerConnection();
      }
    );
  }

  public async deleteAllConnection() {
    if (this.unsubscribeConnectivityCheckConnection) {
      await this.unsubscribeConnectivityCheckConnection();
    }
    this.connectionLruCache.delete();
  }

  public delete({ customerId, projectId }: { customerId: string; projectId: string }) {
    this.connectionLruCache.delete(`${customerId}_${projectId}`);
  }

  public getConnectionCache({ customerId, projectId }: { customerId: string; projectId: string }) {
    const key = `${customerId}_${projectId}`;
    return this.connectionLruCache.get(key);
  }

  public setChatMessageConnectionCache({
    customerId,
    projectId,
    dispose,
  }: {
    customerId: string;
    projectId: string;
    dispose(): void;
  }) {
    const key = `${customerId}_${projectId}`;
    this.connectionLruCache.add({
      key,
      value: { disposeConversationConnection: dispose },
    });
  }

  public setCustomerConnectionCache({
    customerId,
    projectId,
    dispose,
  }: {
    customerId: string;
    projectId: string;
    dispose(): void;
  }) {
    const key = `${customerId}_${projectId}`;
    const cache = this.connectionLruCache.get(key);
    if (cache) {
      this.connectionLruCache.add({
        key,
        value: { ...cache, disposeCustomerConnection: dispose },
      });
    }
  }

  public setTypingConnectionCache({
    customerId,
    projectId,
    dispose,
  }: {
    customerId: string;
    projectId: string;
    dispose(): void;
  }) {
    const key = `${customerId}_${projectId}`;
    const cache = this.connectionLruCache.get(key);
    if (cache) {
      this.connectionLruCache.add({
        key,
        value: { ...cache, disposeTypingConnection: dispose },
      });
    }
  }

  public setConnectivityCheckConnection(unsubscribe: () => Promise<void>) {
    this.unsubscribeConnectivityCheckConnection = unsubscribe;
  }

  public touch({ customerId, projectId }: { customerId: string; projectId: string }) {
    this.connectionLruCache.touch(`${customerId}_${projectId}`);
  }
}

const storeIncidentStoreRetryHandler = createRetryHandler();
const checkLoginRetryHandler = createRetryHandler();

export class FirestoreService implements FirestoreDomainService {
  private readonly cacheConnectionLock: { [key: string]: boolean } = {};
  private readonly firestoreConnectionCacheController: FirestoreConnectionCacheController;
  private readonly updateTypingMap = new Map<string, Promise<void>>();

  public constructor(
    maxChatMessageConnection: number,
    private readonly firestoreConnector: FirestoreConnectable,
    private readonly firestoreConversationViewAdapter: (
      id: string,
      operatorId: string,
      a: FirestoreChatEntity
    ) => ChatEntity,
    private readonly firestoreChatMessageAdapter: (a: MessageFormat) => DisplayableMessageFormat[],
    private readonly firestoreCustomerAdapter: (
      id: string,
      a: RemoteCustomerEntity
    ) => CustomerEntity,
    private readonly sessionUpdater: () => Promise<void>
  ) {
    this.firestoreConnectionCacheController = new FirestoreConnectionCacheController(
      maxChatMessageConnection
    );
  }

  public releaseAllConnection() {
    return this.firestoreConnectionCacheController.deleteAllConnection();
  }

  public measureConnectivity({
    onPingError,
    onConnect,
    onDisconnect,
    onReauth,
    ...a
  }: {
    operatorId: string;
    tenantId: string;
    onReauth(): Promise<void>;
    onPingError(e: any): void;
    onDisconnect(): void;
    onConnect(): void;
  }) {
    const doc_ = this.firestoreConnector.connectToPing(a);
    const ping = async () => {
      setDoc(doc_, { timestamp: new Date() }).catch(e => {
        if ((!isFirebaseError(e) || e.code !== 'permission-denied') && navigator.onLine) {
          onPingError(e);
        }
      });
    };
    let isDisconnected = true;
    let disconnectTimer: any;
    let connectTimer: any;
    const offlineListener = () => {
      clearTimeout(connectTimer);
      isDisconnected = true;
      onDisconnect();
    };
    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', ping);
    const subscribe = () => {
      const unsubscribe = onSnapshot(
        doc_,
        () => {
          if (!navigator.onLine) {
            return;
          }
          setTimeout(ping, 10000);
          clearTimeout(disconnectTimer);
          if (isDisconnected) {
            isDisconnected = false;
            onConnect();
          } else {
            disconnectTimer = setTimeout(() => {
              isDisconnected = true;
              onDisconnect();
            }, 13000);
          }
        },
        async () => {
          clearTimeout(connectTimer);
          await onReauth();
          isDisconnected = true;
          onDisconnect();
          subscribe();
          ping();
        }
      );
      this.firestoreConnectionCacheController.setConnectivityCheckConnection(async () => {
        unsubscribe();
        window.removeEventListener('offline', offlineListener);
        window.removeEventListener('online', ping);
        await deleteDoc(doc_);
      });
      ping();
    };
    subscribe();
  }

  public async getArchivedConversationList({
    projectId,
    tenantId,
    archivedDateRange,
  }: {
    projectId: string | string[];
    tenantId: string;
    archivedDateRange: { from: Date; to: Date };
  }): Promise<ChatEntity[]> {
    await this.sessionUpdater();
    const databases = this.firestoreConnector.connectToArchives({
      tenantId,
      projectId,
      archivedDateRange,
    });
    return Promise.all(
      databases.map(async db => {
        const view = await getDocs(db);
        return view.docs.flatMap(d => {
          const data = d.data();
          return this.firestoreConversationViewAdapter(d.id, '', data as FirestoreChatEntity);
        });
      })
    ).then(v => v.flatMap(v => v));
  }

  public async getArchivedConversations(
    a: Parameters<FirestoreDomainService['getArchivedConversations']>[0]
  ): ReturnType<FirestoreDomainService['getArchivedConversations']> {
    await this.sessionUpdater();
    const database = this.firestoreConnector.connectToArchivedConversations(a);
    const view = await getDocs(database[0]);
    return view.docs.map(doc =>
      this.firestoreConversationViewAdapter(doc.id, '', doc.data() as FirestoreChatEntity)
    );
  }

  public async getArchivedMessages({
    customerId,
    tenantId,
    projectId,
    start,
    end,
  }: Parameters<FirestoreDomainService['getArchivedMessages']>[0]): ReturnType<
    FirestoreDomainService['getArchivedMessages']
  > {
    await this.sessionUpdater();
    const messages = this.firestoreConnector.connectToChatMessages({
      customerId,
      tenantId,
      projectId,
    });
    const ordered = query(
      messages,
      orderBy('at', 'asc'),
      where('at', '>', start),
      where('at', '<', end)
    );

    const docSnapshot = await getDocs(ordered);
    return docSnapshot.docs
      .filter(doc => doc.data()?.messages?.length)
      .flatMap(doc => this.firestoreChatMessageAdapter(doc.data() as MessageFormat));
  }

  public async getCustomer({
    onUpdate,
    projectId,
    onError,
    onReauth,
    ...rest
  }: Parameters<FirestoreDomainService['getCustomer']>[0]) {
    await this.sessionUpdater();
    const database = this.firestoreConnector.connectToCustomer(rest);

    const customerDoc = await getDoc(database);

    const customer = customerDoc.data() as any as RemoteCustomerEntity;
    const convert = (id: string, data: DocumentData) => {
      const remoteCustomerEntity: RemoteCustomerEntity = data as any;
      return this.firestoreCustomerAdapter(id, remoteCustomerEntity);
    };

    const subscribe = () => {
      const dispose = onSnapshot(
        database,
        snapshot => {
          const customer = snapshot.data();
          if (customer) {
            onUpdate(convert(snapshot.id, customer));
          }
        },
        async error => {
          if (error.code !== 'permission-denied') {
            onError(error);
          }
          await this.waitUntilInternetOnline();
          await onReauth();
          subscribe();
        }
      );
      this.firestoreConnectionCacheController.setCustomerConnectionCache({
        customerId: rest.customerId,
        projectId,
        dispose,
      });
    };
    subscribe();
    return convert(customerDoc.id, customer);
  }

  public async handleSubscribeConversationError(
    shouldRetry: boolean,
    error: FirebaseError,
    { dispatch, context, state, getState }: C,
    onRefreshTokenSuccess: () => void,
    onError: () => void
  ) {
    if (shouldRetry && error.code === 'permission-denied') {
      try {
        const id = `${v4()}_${Date.now()}`;
        await checkLoginRetryHandler(id, async () => {
          await checkLoginState({ dispatch, context, state, getState })();
        });
        if (state.auth.isLoggedIn) {
          onRefreshTokenSuccess();
        } else {
          onError();
        }
      } catch (e: any) {
        context.logger.error(error?.message); // FIXME: エラーメッセージ修正
        onError();
      }
    } else {
      context.logger.error(error?.message); // FIXME: エラーメッセージ修正
      onError();
    }
  }

  public async storeIncidentState({
    operatorId,
    ...data
  }: {
    state: State;
    log: Action[];
    operatorId: string;
  }): Promise<string> {
    await this.sessionUpdater();
    const tenantId = data.state.env.tenantId;
    const database = this.firestoreConnector.connectToIncidentStore({
      tenantId,
      operatorId,
    });
    const id = `${v4()}_${Date.now()}`;
    const json = JSON.parse(JSON.stringify(data));
    storeIncidentStoreRetryHandler(
      id,
      async () => {
        await new Promise<void>(async (resolve, reject) => {
          await setDoc(doc(database, id), json).catch(reject);
          resolve();
        });
      },
      { isCheckOnline: true }
    );
    return `https://console.firebase.google.com/project/${staticConfig.firestore.config.projectId}/database/firestore/data~2F${staticConfig.firestore.dataSourceEnv}~2F${tenantId}~2Foperator~2F${operatorId}~2Foperator_incident_state~2F${id}?hl=ja`;
  }

  public async subscribeChatMessages({
    customerId,
    tenantId,
    projectId,
    start,
    isChatStarted,
    onDisconnect,
    onAdd,
    onBatchAdd,
    onRemove,
    onTypingUpdate,
    onUpdateEnableCustomerImageUploadState,
    onError,
    onReauth,
  }: Parameters<FirestoreDomainService['subscribeChatMessages']>[0]): ReturnType<
    FirestoreDomainService['subscribeChatMessages']
  > {
    await this.sessionUpdater();
    const lockKey = `${tenantId}-${projectId}-${customerId}`;
    if (this.cacheConnectionLock[lockKey]) {
      return;
    }
    this.cacheConnectionLock[lockKey] = true;
    const messages = this.firestoreConnector.connectToChatMessages({
      customerId,
      tenantId,
      projectId,
    });
    const ordered = query(messages, where('at', '>', start), orderBy('at', 'asc'));
    const processFirstBatch = async (isReconnected: boolean) => {
      const docSnapshot = await getDocs(ordered);
      const messageList = docSnapshot.docs
        .filter(doc => doc.data()?.messages?.length)
        .flatMap(doc => this.firestoreChatMessageAdapter(doc.data() as MessageFormat));
      onBatchAdd({ messages: messageList, isReconnected });
      return messageList;
    };

    if (await this.isOperatorChatStarted(messages)) {
      this.subscribeTyping({
        customerId,
        tenantId,
        projectId,
        onUpdate: onTypingUpdate,
        onError,
        onReauth,
      });
    }

    const subscribe = async (isReconnected: boolean) => {
      const messageList = await processFirstBatch(isReconnected);
      const dispose = onSnapshot(
        query(ordered, startAfter(messageList.length ? messageList[messageList.length - 1].at : 0)),
        snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === 'added') {
              const data: any = change.doc.data();
              switch (data.type) {
                case 'assignRequest':
                  break;
                case 'assigned':
                  isChatStarted = true;
                  this.subscribeTyping({
                    customerId,
                    tenantId,
                    projectId,
                    onUpdate: onTypingUpdate,
                    onError,
                    onReauth,
                  });
                  break;
                case 'unassigned':
                  isChatStarted = false;
                  break;
                case 'customerImageUpEnableRequest':
                  onUpdateEnableCustomerImageUploadState({ enable: true });
                  break;
                case 'customerImageUpDisableRequest':
                  onUpdateEnableCustomerImageUploadState({ enable: false });
                  break;
                default:
                  if (data?.messages?.length) {
                    if (data.messages.length > 1) {
                      data.messages.forEach((message: MessageFormat) => {
                        const n = this.firestoreChatMessageAdapter({
                          ...data,
                          messages: [message],
                        });
                        onAdd(n, isChatStarted);
                      });
                    } else {
                      onAdd(this.firestoreChatMessageAdapter(data), isChatStarted);
                    }
                  }
              }
            } else if (change.type === 'removed') {
              const data: any = change.doc.data();
              data?.messages?.forEach?.((message: MessageFormat) => {
                onRemove(this.firestoreChatMessageAdapter({ ...data, messages: [message] }));
              });
            }
          });
        },
        async error => {
          if (error.code !== 'permission-denied') {
            onError(error);
          }
          await this.waitUntilInternetOnline();
          await onReauth();
          subscribe(true);
        }
      );
      this.firestoreConnectionCacheController.setChatMessageConnectionCache({
        customerId,
        projectId,
        dispose: () => {
          onDisconnect();
          this.cacheConnectionLock[lockKey] = false;
          dispose();
        },
      });
    };
    subscribe(false);
  }

  public async subscribeConversationViews({
    tenantId,
    filterType,
    projectId,
    operatorId,
    subscriptions,
    onBatchAdd,
    onUpdate,
    onAdd,
    onDelete,
    onError,
    onReauth,
  }: Parameters<FirestoreDomainService['subscribeConversationViews']>[0]): ReturnType<
    FirestoreDomainService['subscribeConversationViews']
  > {
    await this.sessionUpdater();
    const views = this.firestoreConnector.connectToConversationViews({
      tenantId,
      filterType,
      operatorId,
      projectId,
    });

    let isFirstBatch = true;
    const subscribe = () => {
      subscriptions.set(
        filterType,
        onSnapshot(
          views,
          snapshot => {
            if (isFirstBatch) {
              // eslint-disable-next-line no-console
              console.log('subscribeConversationViews first batch'); // NOTE: NECさん調査用: オペレーターchat assignが外れる問題
              onBatchAdd(
                snapshot
                  .docChanges()
                  .map(doc =>
                    this.firestoreConversationViewAdapter(
                      doc.doc.id,
                      operatorId,
                      doc.doc.data() as FirestoreChatEntity
                    )
                  )
              );
              isFirstBatch = false;
            } else {
              snapshot.docChanges().forEach(doc => {
                switch (doc.type) {
                  case 'added':
                    // eslint-disable-next-line no-console
                    console.log('subscribeConversationViews added. doc.doc.id:', doc.doc.id); // NOTE: NECさん調査用: オペレーターchat assignが外れる問題
                    onAdd(
                      this.firestoreConversationViewAdapter(
                        doc.doc.id,
                        operatorId,
                        doc.doc.data() as FirestoreChatEntity
                      )
                    );
                    break;
                  case 'modified':
                    const conversation = doc.doc.data() as FirestoreChatEntity;
                    // eslint-disable-next-line no-console
                    console.log('subscribeConversationViews modified. doc.doc.id: ', doc.doc.id); // NOTE: NECさん調査用: オペレーターchat assignが外れる問題
                    onUpdate(
                      doc.oldIndex,
                      doc.newIndex,
                      this.firestoreConversationViewAdapter(doc.doc.id, operatorId, conversation)
                    );
                    break;
                  case 'removed':
                    // eslint-disable-next-line no-console
                    console.log('subscribeConversationViews removed. doc.doc.id:', doc.doc.id); // NOTE: NECさん調査用: オペレーターchat assignが外れる問題
                    onDelete(
                      this.firestoreConversationViewAdapter(
                        doc.doc.id,
                        operatorId,
                        doc.doc.data() as FirestoreChatEntity
                      )
                    );
                }
              });
            }
          },
          async error => {
            // FIXME: 怪しい... unsubscribeしなきゃだめかも？
            if (error.code !== 'permission-denied') {
              onError(error);
            }
            await this.waitUntilInternetOnline();
            await onReauth();
            subscribe();
          }
        )
      );
    };
    subscribe();
  }

  public async subscribeTyping({
    customerId,
    tenantId,
    projectId,
    onUpdate,
    onError,
    onReauth,
  }: Parameters<FirestoreDomainService['subscribeTyping']>[0]) {
    await this.sessionUpdater();
    const cache = this.firestoreConnectionCacheController.getConnectionCache({
      customerId,
      projectId,
    });
    if (!!cache?.disposeTypingConnection) {
      return;
    }
    const database = this.firestoreConnector.connectToCustomerTyping({
      customerId,
      tenantId,
      projectId,
    });

    const subscribe = () => {
      const dispose = onSnapshot(
        database,
        doc => {
          if (doc.exists()) {
            onUpdate({ text: doc.data()!.text, isTyping: true });
          } else {
            onUpdate({ text: '', isTyping: false });
          }
        },
        async error => {
          if (error.code !== 'permission-denied') {
            onError(error);
          }
          await this.waitUntilInternetOnline();
          await onReauth();
          subscribe();
        }
      );
      this.firestoreConnectionCacheController.setTypingConnectionCache({
        customerId,
        projectId,
        dispose,
      });
    };
    subscribe();
  }

  public async subscribeVoiceConversation({
    tenantId,
    projectId,
    onAdd,
    onUpdate,
    onDelete,
    onError,
  }: Parameters<FirestoreDomainService['subscribeVoiceConversation']>[0]): ReturnType<
    FirestoreDomainService['subscribeVoiceConversation']
  > {
    await this.sessionUpdater();
    const views = this.firestoreConnector.connectToVoiceConversation({
      tenantId,
      projectId,
    });
    let isFirstBatch = true;
    onSnapshot(
      views,
      snapshot => {
        if (isFirstBatch) {
          const voiceConversations = snapshot.docChanges().map(doc => {
            return this.convertToVoiceConversation(doc);
          });
          voiceConversations.map(conversation => {
            onAdd(conversation);
          });
          isFirstBatch = false;
        } else {
          snapshot.docChanges().forEach(doc => {
            switch (doc.type) {
              case 'added': {
                const conversation = this.convertToVoiceConversation(doc);
                onAdd(conversation);
                break;
              }
              case 'modified': {
                const conversation = this.convertToVoiceConversation(doc);
                onUpdate(conversation);
                break;
              }
              case 'removed': {
                const conversation = this.convertToVoiceConversation(doc);
                onDelete(conversation);
                break;
              }
            }
          });
        }
      },
      error => {
        if (isFirebaseError(error)) {
          onError(error);
        }
      }
    );
  }

  public async subscribeVoiceConversationEvent({
    tenantId,
    voiceCustomerId,
    projectId,
    conversationId,
    onBatchAdd,
    onAdd,
    onUpdate,
    onDelete,
    onError,
  }: Parameters<FirestoreDomainService['subscribeVoiceConversationEvent']>[0]): ReturnType<
    FirestoreDomainService['subscribeVoiceConversationEvent']
  > {
    await this.sessionUpdater();
    const conversationEvents = this.firestoreConnector.connectToVoiceConversationEvents({
      tenantId,
      voiceCustomerId,
      projectId,
      conversationId,
    });
    const query_ = query(conversationEvents, orderBy('created_at', 'asc'));
    let isFirstBatch = true;
    onSnapshot(
      query_,
      snapshot => {
        if (isFirstBatch) {
          const conversationEvents = snapshot.docChanges().map(doc => {
            return {
              id: doc.doc.id,
              ...doc.doc.data(),
              senderType: doc.doc.data()['sender_type'],
              createdAt: doc.doc.data()['created_at'],
              formattedEntity: doc.doc.data().entity && formatVoiceEntity(doc.doc.data().entity),
            } as ConversationEvent;
          });
          onBatchAdd(conversationEvents);
          isFirstBatch = false;
        } else {
          snapshot.docChanges().forEach(doc => {
            const conversationEvent = {
              id: doc.doc.id,
              ...doc.doc.data(),
              senderType: doc.doc.data()['sender_type'],
              createdAt: doc.doc.data()['created_at'],
              formattedEntity: doc.doc.data().entity && formatVoiceEntity(doc.doc.data().entity),
            } as ConversationEvent;
            if (doc.type === 'added') {
              onAdd(conversationEvent);
            } else if (doc.type === 'modified') {
              onUpdate(conversationEvent);
            } else {
              onDelete(conversationEvent);
            }
          });
        }
      },
      error => {
        if (isFirebaseError(error)) {
          onError(error);
        }
      }
    );
  }

  public unsubscribeAllChatMessages({
    tenantId,
    projectId,
  }: Parameters<FirestoreDomainService['unsubscribeAllChatMessages']>[0]): ReturnType<
    FirestoreDomainService['unsubscribeAllChatMessages']
  > {
    const lockKeyPrefix = `${tenantId}-${projectId}`;
    const customerIds = Object.keys(this.cacheConnectionLock)
      .filter(key => {
        return key.startsWith(lockKeyPrefix);
      })
      .map(key => {
        return key.replace(`${lockKeyPrefix}-`, '');
      });
    for (const customerId of customerIds) {
      this.firestoreConnectionCacheController.delete({ customerId, projectId });
    }
  }

  public unsubscribeChatMessages({
    customerId,
    projectId,
  }: Parameters<FirestoreDomainService['unsubscribeChatMessages']>[0]): void {
    this.firestoreConnectionCacheController.delete({ customerId, projectId });
  }

  public updateCacheRecency({
    customerId,
    projectId,
  }: {
    customerId: string;
    projectId: string;
  }): void {
    this.firestoreConnectionCacheController.touch({ customerId, projectId });
  }

  public async updateTyping({
    tenantId,
    projectId,
    customerId,
    isTyping,
  }: Parameters<FirestoreDomainService['updateTyping']>[0]): ReturnType<
    FirestoreDomainService['updateTyping']
  > {
    const key = customerId;
    if (isTyping && this.updateTypingMap.has(key)) {
      return this.updateTypingMap.get(key);
    }
    const promise = new Promise<void>(async (resolve, reject) => {
      await this.sessionUpdater();
      const database = this.firestoreConnector.connectToOperatorTyping({
        customerId,
        tenantId,
        projectId,
      });
      if (isTyping) {
        await setDoc(database, { timestamp: new Date() }).catch(reject);
      } else {
        await deleteDoc(database).catch(reject);
        this.updateTypingMap.delete(key);
      }
      resolve();
      if (isTyping) {
        setTimeout(() => {
          this.updateTypingMap.delete(key);
        }, 500);
      }
    });
    if (isTyping) {
      this.updateTypingMap.set(key, promise);
    }
    return promise;
  }

  private convertToVoiceConversation(doc: DocumentChange<DocumentData>) {
    const voiceCustomer = {
      id: doc.doc.data()['for_query']['customer_id'],
      customerPhoneNumber: doc.doc.data()['for_query']['customer_phone_number'],
      aimPhoneNumber: '',
      note: '',
    } as VoiceCustomer;
    return {
      id: doc.doc.id,
      ...doc.doc.data(),
      voiceCustomer,
      status: doc.doc.data()['status'],
      direction: doc.doc.data()['direction'],
      isActive: doc.doc.data()['is_active'],
      createdAt: doc.doc.data()['created_at'],
      formattedEntity: doc.doc.data().entity && formatVoiceEntity(doc.doc.data().entity),
      labelIds: doc.doc.data()['label_ids'],
    } as Conversation;
  }

  private async isOperatorChatStarted(
    database: CollectionReference<DocumentData>
  ): Promise<boolean> {
    await this.sessionUpdater();
    const query_ = query(
      database,
      orderBy('at', 'desc'),
      where('type', 'in', ['assigned', 'unassigned', 'closed'])
    );

    const events = await getDocs(query_);

    return !!events.docs.length && events.docs[0].data().type === 'assigned';
  }

  private async waitUntilInternetOnline() {
    return new Promise<void>(resolve => {
      const check = () => {
        if (navigator.onLine) {
          clearInterval(id);
          resolve();
        }
      };
      const id = setInterval(check, 1000);
      check();
    });
  }
}
