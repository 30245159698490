/**
 * @fileOverview
 * @author
 */

import { State } from '@c/state';
import { ThunkDeps } from '@c/ThunkDeps';
import { genericError } from '@c/application/GenericError';
import { getEnvSucceeded, getProjectIdSucceeded, latestUrlChanged } from '@c/modules/env/action';
import { AsyncActionContext } from '@s/reactHooks';
import { match } from 'react-router';
import { getAuth } from 'firebase/auth';
import { isExperimentalForceLongPollingTenant, staticConfig } from '@c/config';
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { getUnicode, Locale } from '@c/shared/utils/i18n';

export const fetchEnv =
  ({ context, state, dispatch }: AsyncActionContext<ThunkDeps, State>) =>
  async () => {
    const unicode = getUnicode(Locale.jaJP);
    try {
      const env = await context.envQuery.get();
      const app = initializeApp(env.firebaseConfig ?? staticConfig.firestore.config);
      const auth = getAuth();
      auth.tenantId = env.tenantId;
      auth.languageCode = 'ja';
      if (isExperimentalForceLongPollingTenant()) {
        initializeFirestore(app, {
          experimentalForceLongPolling: true,
        });
      }
      dispatch(getEnvSucceeded(env));
    } catch (e: any) {
      context.logger.error(unicode.operation.fail({ kinds: '環境変数の取得' }), e.message);
      throw genericError({ message: '環境変数の取得に失敗しました' });
    }
  };

export const fetchProjectId =
  ({ context, state, dispatch }: AsyncActionContext<ThunkDeps, State>) =>
  async () => {
    const unicode = getUnicode(Locale.jaJP);
    try {
      const pid = await context.projectIdQuery.get(state.env.tenantId);
      dispatch(getProjectIdSucceeded(pid));
    } catch (e: any) {
      context.logger.error(unicode.operation.fail({ kinds: 'プロジェクトIDの取得' }), e.message);
      throw genericError({
        message: 'プロジェクトIDの取得に失敗しました',
      });
    }
  };

export const setLatestUrl =
  ({ dispatch }: AsyncActionContext<ThunkDeps, State>) =>
  async ({ match }: { match: match }) => {
    dispatch(latestUrlChanged({ match }));
  };
