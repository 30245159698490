/**
 * @fileOverview
 * @name firebaseErrorHandler.ts
 * @author Taketoshi Aono
 * @license
 */

import { genericError, GenericError, RequiredAction } from '@c/application/GenericError';

export type FirebaseErrorType =
  | 'auth/id-token-expired'
  | 'auth/id-token-revoked'
  | 'auth/requires-recent-login'
  | 'auth/user-token-expired'
  | 'auth/invalid-argument'
  | 'auth/invalid-claims'
  | 'auth/invalid-email'
  | 'auth/invalid-display-name'
  | 'auth/invalid-id-token'
  | 'auth/invalid-password'
  | 'auth/session-cookie-expired'
  | 'auth/session-cookie-revoked'
  | 'auth/invalid-credential'
  | 'auth/web-storage-unsupported'
  | 'auth/too-many-requests'
  | 'auth/invalid-action-code'
  | 'permission-denied'
  | 'auth/account-exists-with-different-credential';

export type FirebaseError = {
  code: FirebaseErrorType;
  name: string;
  message: string;
};

export const getErrorMessageAndAction = (
  code: FirebaseErrorType
): { message: string; requiredAction?: RequiredAction } => {
  switch (code) {
    case 'auth/invalid-argument':
    case 'auth/invalid-claims':
    case 'auth/invalid-action-code':
      return {
        message: 'メールのリンクが不正か期限切れです',
      };
    case 'auth/id-token-revoked':
      return {
        message: '操作しようとしたユーザーは管理者によって無効化されています',
      };
    case 'auth/invalid-credential':
      return { message: '認証エラーが発生しました' };
    case 'auth/id-token-expired':
    case 'auth/invalid-id-token':
    case 'auth/session-cookie-expired':
    case 'auth/session-cookie-revoked':
    case 'auth/requires-recent-login':
    case 'auth/user-token-expired':
      return {
        message: 'セッションが切れています。再ログインしてください',
        requiredAction: { type: 'push', args: ['/login'] },
      };
    case 'auth/invalid-email':
      return { message: '不正なemailアドレスです' };
    case 'auth/invalid-display-name':
      return { message: '不正な表示名です' };
    case 'auth/invalid-password':
      return {
        message: 'パスワードは6文字以上でなければなりません',
      };
    case 'auth/web-storage-unsupported':
      return {
        message:
          'ブラウザが対応していません。シークレットモードの場合は、通常モードに切り替えてご利用ください',
      };
    case 'auth/too-many-requests':
      return {
        message: '試行回数が多すぎます。時間を開けて再度お試しください',
      };
    case 'auth/account-exists-with-different-credential':
      return {
        message: 'このアカウントはお試しのログイン方法ではご利用できません',
      };
    default:
      return { message: 'エラーが発生しました' };
  }
};

export const isFirebaseError = (e: any): e is FirebaseError => {
  return !!e?.code;
};

export const firebaseErrorHandler = (
  e: FirebaseError | any,
  or: (e: any) => GenericError
): GenericError => {
  if (isFirebaseError(e)) {
    return genericError({
      ...getErrorMessageAndAction(e.code),
    });
  }
  return or(e);
};
