export type WidgetEventHandlers = {
  onImmediate?(): void;
  onMount?(): void;
  onOpen?(): void;
  onClose?(): void;
  onLinkClick?(): void;
  onButtonClick?(a: { label: string }): void;
};

export interface WidgetConfigurationCustomize {
  floatingIcon: {
    color?: string;
    size?: { width: number; height: number };
    src?: string;
    position: 'left-bottom' | 'right-bottom';
    distanceFromScreenEdges?: { x: number; y: number };
  };
  messages?: {
    headerText?: string;
    balloonText?: string;
  };
  themeColor: string;
  icons?: {
    bot?: string;
    customer?: string;
    operator?: string;
  };
  floatingChatWindowHeight?: number;
  enableInputMasking?: boolean;
}

export interface WidgetWhitelist {
  acceptDomains?: string[];
  acceptUrl?: string[];
}
export interface WidgetConfiguration {
  whitelist: WidgetWhitelist;
  customization: WidgetConfigurationCustomize;
  events?: { [P in keyof WidgetEventHandlers]?: [string, string] };
}

export const defaultWidgetConfiguration: WidgetConfiguration = {
  whitelist: {
    acceptUrl: ['https://*ai-messenger.app'],
  },
  customization: {
    floatingIcon: {
      position: 'right-bottom',
      distanceFromScreenEdges: { x: 0, y: 0 },
    },
    messages: {
      balloonText: 'ようこそ',
      headerText: 'チャットサポート',
    },
    themeColor: '#0066FF',
    floatingChatWindowHeight: undefined,
    enableInputMasking: false,
  },
};

export const mergeCustomization = (
  a: WidgetConfigurationCustomize = defaultWidgetConfiguration.customization,
  b: WidgetConfigurationCustomize = defaultWidgetConfiguration.customization
): WidgetConfigurationCustomize => {
  const defaultSize = { width: 100, height: 100 };
  return {
    floatingIcon: {
      ...a.floatingIcon,
      ...b.floatingIcon,
      size: {
        ...(a.floatingIcon.size ?? defaultSize),
        ...(b.floatingIcon.size ?? defaultSize),
      },
      distanceFromScreenEdges: {
        ...(a.floatingIcon.distanceFromScreenEdges ?? { x: 0, y: 0 }),
        ...(b.floatingIcon.distanceFromScreenEdges ?? { x: 0, y: 0 }),
      },
    },
    messages: {
      ...(a.messages ?? {}),
      ...(b.messages ?? {}),
    },
    themeColor: b.themeColor,
    icons: {
      ...(a.icons ?? {}),
      ...(b.icons ?? {}),
    },
    floatingChatWindowHeight: b.floatingChatWindowHeight,
  };
};
