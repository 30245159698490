/**
 * @fileOverview
 * @name OperationHistoriesQuery.ts
 * @author Taketoshi Aono
 * @license
 */

import { GettableQuery } from './interface';
import {
  OperationHistoriesWithV2,
  OperationHistoryV2,
} from '@c/domain/entities/OperationHistoriesV2';
import { ENV } from '@c/config';
import { getDocs, getFirestore, collection, query, orderBy, limit } from 'firebase/firestore';

export type OperationHistoriesGettableQuery = GettableQuery<
  { tenantId: string; projectId: string },
  OperationHistoriesWithV2
>;

export class OperationHistoriesQuery implements OperationHistoriesGettableQuery {
  public constructor() {}

  public async get({
    tenantId,
    projectId,
  }: Parameters<OperationHistoriesGettableQuery['get']>[0]): ReturnType<
    OperationHistoriesGettableQuery['get']
  > {
    // const firestore = this.getFirebaseInstance().firestore();
    const db = getFirestore();

    // MEMO: 旧operationHistoryは、全てこちらのprojectSnapshotに集約される.

    const collectinRef = collection(
      db,
      ENV,
      tenantId,
      'projects',
      projectId,
      'operation_histories'
    );
    const q1 = query(collectinRef, orderBy('at', 'desc'), limit(3000));
    const snapshotV1 = await getDocs(q1);

    try {
      const q2 = query(
        collection(db, ENV, tenantId, 'operation_histories'),
        orderBy('created_at', 'desc'),
        limit(1000)
      );
      const snapshotV2 = await getDocs(q2);

      const v2Data = snapshotV2.docs.map(doc => {
        return {
          ...doc.data(),
          operatorId: doc.data()['operator_id'],
          tenantId: doc.data()['tenant_id'],
          operatorVisibility: doc.data()['operator_visibility'],
          operationData:
            'operation_data' in doc.data()
              ? {
                  systemMessageType:
                    'system_message_type' in doc.data()['operation_data']
                      ? doc.data()['operation_data']['system_message_type']
                      : '',
                  displayName:
                    'display_name' in doc.data()['operation_data']
                      ? doc.data()['operation_data']['display_name']
                      : '',
                  newDisplayName:
                    'new_display_name' in doc.data()['operation_data']
                      ? doc.data()['operation_data']['new_display_name']
                      : '',
                  oldDisplayName:
                    'old_display_name' in doc.data()['operation_data']
                      ? doc.data()['operation_data']['old_display_name']
                      : '',
                  lineSetting:
                    'line_setting' in doc.data()['operation_data']
                      ? doc.data()['operation_data']['line_setting']
                      : '',
                }
              : {
                  systemMessageType: '',
                  displayName: '',
                },
          createdAt: doc.data()['created_at'],
        } as OperationHistoryV2;
      });

      return [...v2Data, ...snapshotV1.docs.map(doc => doc.data())] as OperationHistoriesWithV2;
    } catch (err) {
      // MEMO: テナントに新しい操作履歴がまだ作成されていない( /(env)/(tenant_id)/operation_histories のcollectionが
      //       作成されていない )場合、firestoreのget()が例外を飛ばす.
      //       その際は、ここのcatch blockでこれまで通り旧履歴のみを返す.
      return [...snapshotV1.docs.map(doc => doc.data())] as OperationHistoriesWithV2;
    }
  }
}
