/**
 * @fileOverview
 * @name Toast.tsx
 * @author Taketoshi Aono
 * @license
 */

import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import { useRefState } from '@s/reactHooks';

export const toastStyle = css`
  .Toastify {
    position: relative;
    z-index: 9999999;
  }
  .Toastify__toast {
    border-radius: 8px;
  }
  .Toastify__toast-body {
    padding: 20px;
  }
`;

export class Notificator {
  private failureId!: number | string;
  private successId!: number | string;

  public constructor(private readonly option: ToastOptions = {}) {}

  public close() {
    if (this.successId) {
      toast.dismiss(this.successId);
      this.successId = '';
    }
    if (this.failureId) {
      toast.dismiss(this.failureId);
      this.failureId = '';
    }
  }

  public error(msg: string | React.ReactNode | { (): void }) {
    const opt = {
      position: 'top-right' as const,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...this.option,
      onClose: () => {
        this.option.onClose && this.option.onClose();
        this.failureId = '';
      },
    };

    if (this.failureId) {
      toast.update(this.failureId, {
        type: 'error',
        render: msg,
        ...opt,
      });
    } else {
      this.failureId = toast.error(msg, opt);
    }
  }

  public success(msg: string, topt: ToastOptions = {}) {
    const opt = {
      position: 'top-right' as const,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      ...this.option,
      ...topt,
      onClose: () => {
        topt.onClose && topt.onClose();
        this.option.onClose && this.option.onClose();
        this.successId = '';
      },
    };
    if (!this.successId) {
      this.successId = toast.success(msg, opt);
    } else {
      toast.update(this.successId, { render: msg, type: 'success', ...opt });
    }
  }
}

export const useNotificator = (option: ToastOptions = {}): Notificator => {
  const [notificator, setNotificator] = useRefState<Notificator | null>(null);
  if (!notificator.current) {
    const ret = new Notificator(option);
    setNotificator(ret);
    return ret;
  }

  return notificator.current;
};
