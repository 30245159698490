import { convertRawProjectToProjectEntity, ProjectEntity, RawProject } from './Project';

export enum OperatorErrorType {
  UNSPECIFIED = 'UNSPECIFIED',
  EMAIL_VERIFICATION_NOT_FOUND = 'EMAIL_VERIFICATION_NOT_FOUND',
  OOB_CODE_EXPIRED = 'OOB_CODE_EXPIRED',
  EMAIL_ALREADY_VERIFIED = 'EMAIL_ALREADY_VERIFIED',
}

export type Operator = {
  id: string;
  aimUserId: string;
  displayName: string;
  email: string;
  operatorRoleType: string;
  emailVerified: boolean;
  lastLoginAt?: string;
  disabledAt?: string;
  createdAt: string;
  updatedAt: string;
};

export type GetOperatorsResponse = {
  operators: Operator[];
  pagination: {
    currentPage: number;
    hasNext: boolean;
    prevPage: number;
    nextPage: number;
    totalPage: number;
  };
};

export type RawOperator = {
  id: string;
  aim_user_id: string;
  display_name: string;
  email: string;
  operator_role_type: string;
  disabled_at?: string;
  created_at: string;
  updated_at: string;
  auth_user: {
    id: string;
    email: string;
    email_verified: boolean;
    display_name: string;
    disabled: boolean;
    last_login_at: string;
    custom_claims: {
      aim_user_id: string;
      operator_role_type: string;
      user_type: number;
      verified: boolean;
    };
  };
};

export type RawGetOperatorsResponse = {
  operators: RawOperator[];
  pagination: {
    current_page: number;
    has_next: boolean;
    prev_page: number;
    next_page: number;
    total_page: number;
  };
};

export function convertOperator(rawOperator: RawOperator): Operator {
  return {
    id: rawOperator.id,
    aimUserId: rawOperator.aim_user_id,
    displayName: rawOperator.display_name,
    email: rawOperator.email,
    operatorRoleType: rawOperator.operator_role_type,
    emailVerified: rawOperator.auth_user.email_verified,
    disabledAt: rawOperator.disabled_at,
    lastLoginAt: rawOperator.auth_user.last_login_at,
    createdAt: rawOperator.created_at,
    updatedAt: rawOperator.updated_at,
  };
}

export function convertGetOperatorsResponse(
  rawGetOperatorsResponse: RawGetOperatorsResponse
): GetOperatorsResponse {
  return {
    operators: rawGetOperatorsResponse.operators.map(convertOperator),
    pagination: {
      currentPage: rawGetOperatorsResponse.pagination.current_page,
      hasNext: rawGetOperatorsResponse.pagination.has_next,
      prevPage: rawGetOperatorsResponse.pagination.prev_page,
      nextPage: rawGetOperatorsResponse.pagination.next_page,
      totalPage: rawGetOperatorsResponse.pagination.total_page,
    },
  };
}

export type RawGetOperatorResponse = {
  operator: RawOperator;
  assigned_projects?: RawProject[];
};

export type GetOperatorResponse = {
  operator: Operator;
  assignedProjects: ProjectEntity[];
};

export function convertGetOperatorResponse(
  rawGetOperatorResponse: RawGetOperatorResponse
): GetOperatorResponse {
  return {
    operator: {
      id: rawGetOperatorResponse.operator.id,
      aimUserId: rawGetOperatorResponse.operator.aim_user_id,
      displayName: rawGetOperatorResponse.operator.display_name,
      email: rawGetOperatorResponse.operator.email,
      operatorRoleType: rawGetOperatorResponse.operator.operator_role_type,
      emailVerified: rawGetOperatorResponse.operator.auth_user.email_verified,
      lastLoginAt: rawGetOperatorResponse.operator.auth_user.last_login_at,
      disabledAt: rawGetOperatorResponse.operator.disabled_at,
      createdAt: rawGetOperatorResponse.operator.created_at,
      updatedAt: rawGetOperatorResponse.operator.updated_at,
    },
    assignedProjects:
      rawGetOperatorResponse?.assigned_projects?.map(convertRawProjectToProjectEntity) ?? [],
  };
}

type OperatorMe = {
  id: string;
  authUserId: string;
  displayName: string;
  email: string;
  operatorRoleType: string;
  temporaryPasswordUpdatedAt?: string;
  disabledAt?: string;
  createdAt: string;
  updatedAt: string;
};

type RawOperatorMe = {
  id: string;
  auth_user_id: string;
  display_name: string;
  email: string;
  operator_role_type: string;
  temporary_password_updated_at?: string;
  disabled_at?: string;
  created_at: string;
  updated_at: string;
  auth_user: {
    id: string;
    email: string;
    display_name: string;
    disabled: boolean;
    custom_claims: {
      aim_user_id: string;
      operator_role_type: string;
      user_type: number;
      verified: boolean;
    };
  };
};

export type RawGetMeResponse = {
  operator: RawOperatorMe;
};

function convertOperatorMe(rawOperator: RawOperatorMe): OperatorMe {
  return {
    id: rawOperator.id,
    authUserId: rawOperator.auth_user_id,
    displayName: rawOperator.display_name,
    email: rawOperator.email,
    operatorRoleType: rawOperator.operator_role_type,
    temporaryPasswordUpdatedAt: rawOperator.temporary_password_updated_at,
    disabledAt: rawOperator.disabled_at,
    createdAt: rawOperator.created_at,
    updatedAt: rawOperator.updated_at,
  };
}

export function convertOperatorMeResponse(rawGetMeResponse: RawGetMeResponse): OperatorMe {
  return convertOperatorMe(rawGetMeResponse.operator);
}
