import { Locale, unicodeFactory } from './IUnicode';

export const unicodeDictionary: unicodeFactory = {
  jaJP: {
    // 以下は例です。実態に合わせて変更してください。
    error: {
      network: ({ reason }) => `ネットワークエラー: ${reason}再試行してください。`,
      authorization: () => '権限がありません',
    },
    fetch: {
      failToGet: ({ kinds }) => `${kinds ?? 'データ'}の取得に失敗しました。`,
    },
    operation: {
      save: ({ kinds }) => `${kinds}を保存しました。`,
      failToSave: ({ kinds }) => `${kinds}の保存に失敗しました。`,
      add: ({ kinds }) => `${kinds}を追加しました。`,
      failToAdd: ({ kinds }) => `${kinds}の追加に失敗しました。`,
      delete: ({ kinds }) => `${kinds}を削除しました。`,
      failToDelete: ({ kinds }) => `${kinds}の削除に失敗しました。`,
      update: ({ kinds }) => `${kinds}を更新しました。`,
      failToUpdate: ({ kinds }) => `${kinds}の更新に失敗しました。`,
      create: ({ kinds }) => `${kinds}を作成しました。`,
      failToCreate: ({ kinds }) => `${kinds}の作成に失敗しました。`,
      upload: ({ kinds }) => `${kinds}をアップロードしました。`,
      failToUpload: ({ kinds }) => `${kinds}のアップロードに失敗しました。`,
      register: ({ kinds }) => `${kinds}を登録しました。`,
      failToRegister: ({ kinds }) => `${kinds}の登録に失敗しました。`,
      alreadyRegistered: ({ kinds }) => `${kinds}はすでに登録されています。`,
      alreadyDeleted: ({ kinds }) => `${kinds}はすでに削除されています。`,
      alreadyProcessing: ({ kinds }) => `${kinds}はすでに処理中です。`,
      failToParse: ({ kinds }) => `${kinds}のパースに失敗しました。`,
      failToSetting: ({ kinds }) => `${kinds}の設定に失敗しました。`,
      downloaded: ({ kinds }) => `${kinds}をダウンロードしました。`,
      failToDownload: ({ kinds }) => `${kinds}のダウンロードに失敗しました。`,
      fail: ({ kinds }) => `${kinds}に失敗しました。`,
      select: ({ kinds }) => `${kinds}を選択しました。`,
      failToSelect: ({ kinds }) => `${kinds}の選択に失敗しました。`,
      login: ({ kinds }) => `${kinds}にログインしました。`,
      failToLogin: ({ kinds }) => `${kinds}のログインに失敗しました。`,
      failToVerify: ({ kinds }) => `${kinds}の検証に失敗しました。`,
    },
    validation: {
      username: ({ min, max }) => `ユーザー名は${min}文字以上${max}文字以下である必要があります。`,
      password: ({ min, max }) => `パスワードは${min}文字以上${max}文字以下である必要があります。`,
      jsonSchema: ({ kinds }) => `${kinds}はJSON Schemaに適合していません。`,
      hankaku: () => '半角でご記入ください。',
    },
    // ...他
  },
};

/**
 * @param locale デフォルトはjaJPです
 * @returns unicodeDictionaryの中からlocaleに合致するものを返します
 */
export const getUnicode = (locale: Locale = 'jaJP') => {
  return unicodeDictionary[locale as keyof typeof unicodeDictionary];
};
