/**
 * @fileoverview
 * @author Taketoshi Aono
 */
import {
  MessageFormat,
  ServiceSideMessageSender,
  WidgetMessageFormatType,
  QuickReply,
  ImagePayload,
  QuickReplyPostbackPayload,
  MessageSender,
  LLMMessageFormat,
} from '@s/domain/entity/MessageFormat';
import { InputStateValues } from '@s/domain/values/InputStateValues';

export interface WidgetMessageConfig {
  Customer: {
    color: string;
    backgroundColor: string;
  };
  Bot: {
    color: string;
    backgroundColor: string;
  };
}

export const createWidgetMessageStyle = ({
  config,
  type,
}: {
  config: WidgetMessageConfig;
  type: ServiceSideMessageSender;
}) => {
  return {
    color: config[type].color,
    backgroundColor: config[type].backgroundColor,
  };
};

interface BaseDisplayableMessageFormat {
  type: WidgetMessageFormatType;
  quickReplies?: QuickReply[];
  text: string;
  textInputState: InputStateValues;
  sender: {
    type: MessageSender;
    name?: string;
    id?: string;
  };
  id?: string;
  uuid?: string;
  isDelayNextMessage: boolean;
  delayValue: number;
  at: number;
  viewAt: number;
  isTyping?: boolean;
  createdAt?: number;
  isWelcomeByBot: boolean;
  isWelcome: boolean;
  meta: any;
}

export type DisplayableMessageFormat =
  | (BaseDisplayableMessageFormat & {
      type: 'plainText';
      payload: string;
      seekTime?: number;
    })
  /* eslint-disable @typescript-eslint/naming-convention */
  | (BaseDisplayableMessageFormat & { type: 'instagramStoryMention'; payload: { URL: string } })
  /* eslint-enable @typescript-eslint/naming-convention */
  | (BaseDisplayableMessageFormat & {
      type: 'instagramStoryReply';
      /* eslint-disable @typescript-eslint/naming-convention */
      payload: { ReplyStoryURL: string; Text: string };
      /* eslint-enable @typescript-eslint/naming-convention */
    })
  | (BaseDisplayableMessageFormat & {
      type: 'instagramMessageDelete';
      /* eslint-disable @typescript-eslint/naming-convention */
      payload: { MessageId: string };
      /* eslint-enable @typescript-eslint/naming-convention */
    })
  | (BaseDisplayableMessageFormat & { type: 'image'; payload: ImagePayload })
  | (BaseDisplayableMessageFormat & { type: 'postback'; payload: QuickReplyPostbackPayload });

export const serializePayload = (messages: DisplayableMessageFormat[]) => {
  return messages
    .reduce((texts, message) => {
      switch (message.type) {
        case 'plainText':
          return texts.concat([message.payload]);
        case 'image':
          return texts.concat(['画像ファイル']);
        default:
          return texts;
      }
    }, [] as string[])
    .join('\n');
};

export const convertMessageFormatToWidgetMessage = (
  messageFormat: MessageFormat,
  endpointBaseURL = ''
): DisplayableMessageFormat[] => {
  return messageFormat.messages.map((msg, index) => {
    if (endpointBaseURL && msg.attachment.mediaType === 'image') {
      const url = new URL(msg.attachment.payload.url);
      const previewUrl = new URL(msg.attachment.payload.previewUrl);
      msg.attachment.payload.url = `${endpointBaseURL}${url.pathname}${url.search}${url.hash}`;
      msg.attachment.payload.previewUrl = `${endpointBaseURL}${previewUrl.pathname}${previewUrl.search}${previewUrl.hash}`;
    }
    const m: DisplayableMessageFormat = {
      type: msg.attachment.mediaType as any,
      text:
        msg.attachment.mediaType === 'postback'
          ? msg.attachment.payload.text
          : msg.attachment.mediaType === 'plainText'
          ? msg.attachment.payload
          : '(表示できません)',
      sender: {
        type: messageFormat.sender.type ?? 'Customer',
      },
      textInputState: messageFormat?.meta?.textInputState ?? 'show',
      at: messageFormat.at + index,
      viewAt: (messageFormat.viewAt ?? messageFormat.at) + index,
      uuid:
        messageFormat.sender.type === 'Customer'
          ? messageFormat.meta?.uuid ?? msg.id
          : msg.id ?? '',
      payload: msg.attachment.payload as any,
      isDelayNextMessage: !!msg.meta?.delay,
      delayValue: (msg.meta?.delay as unknown as number) ?? 0,
      isWelcomeByBot:
        (!!messageFormat.meta?.isWelcome && messageFormat.sender.type === 'Bot') ||
        messageFormat.at <= 0,
      isWelcome: !!messageFormat.meta?.isWelcome && messageFormat.sender.type === 'Bot',
      meta: messageFormat.meta ?? {},
    };
    if (msg.attachment?.quickReplies) {
      m.quickReplies = msg.attachment.quickReplies.slice();
    }
    return m;
  });
};

export const convertLLMMessageFormatToWidgetMessage = (
  messageFormat: LLMMessageFormat,
  endpointBaseURL = ''
): DisplayableMessageFormat[] => {
  return messageFormat.messages.map((msg, index) => {
    if (endpointBaseURL && msg.attachment.mediaType === 'image') {
      const url = new URL(msg.attachment.payload.url);
      const thumbnailUrl = new URL(msg.attachment.payload.thumbnailUrl);
      msg.attachment.payload.url = `${endpointBaseURL}${url.pathname}${url.search}${url.hash}`;
      msg.attachment.payload.thumbnailUrl = `${endpointBaseURL}${thumbnailUrl.pathname}${thumbnailUrl.search}${thumbnailUrl.hash}`;
    }
    const m: DisplayableMessageFormat = {
      type: msg.attachment.mediaType as any,
      text:
        msg.attachment.mediaType === 'postback'
          ? msg.attachment.payload.text
          : msg.attachment.mediaType === 'plainText'
          ? msg.attachment.payload
          : '(表示できません)',
      sender: {
        type: messageFormat.sender.type ?? 'Customer',
      },
      textInputState: 'show',
      at: messageFormat.at + index,
      viewAt: messageFormat.at + index,
      uuid: messageFormat.senderMessageId ?? '',
      payload: msg.attachment.payload as any,
      isDelayNextMessage: false,
      delayValue: 0,
      isWelcomeByBot: messageFormat.isWelcome,
      isWelcome: messageFormat.isWelcome,
      meta: {
        uuid: messageFormat.senderMessageId,
      },
    };
    if (msg.attachment?.quickReplies) {
      m.quickReplies = msg.attachment.quickReplies.slice();
    }
    return m;
  });
};
